import { useMemo } from 'react'

import { createLink } from '../function/createLink'

/**
 * Оставлю в виде хука, на случай, если нужно будет расширять данными из state
 */
export const useMobileAppUrl = (): string | undefined => {
  return useMemo(() => {
    if (process.env.browser) {
      return createLink()
    }

    return undefined
  }, [])
}
