import { useMemo } from 'react'

import { css } from 'styled-components'

import { StyledComponentsStyles } from 'common/types'
import { Colors } from 'common-constants/colors'
import mambaLightSvg from 'components/banner/InstallAppBanner/assets/mamba-light.svg'
import mambaSvg from 'components/banner/InstallAppBanner/assets/mamba.svg'

import loveMailRuPng from '../assets/loveMailRu.png'
import wambaSvg from '../assets/wamba.svg'
import yonjaSvg from '../assets/yonja.svg'

export const useContent = ({
  loveMailRu,
  wamba,
  yonja,
  mambaLight,
}: {
  loveMailRu: boolean
  wamba: boolean
  yonja: boolean
  mambaLight: boolean
}) => {
  return useMemo((): {
    logoSrc: string
    title: string
    alias: string
    containerStyles: StyledComponentsStyles
  } => {
    if (loveMailRu) {
      return {
        logoSrc: loveMailRuPng,
        title: 'Love@Mail.RU',
        alias: 'lovemailru',
        containerStyles: css`
          background: ${Colors.mailruBackground};
        `,
      }
    }
    if (wamba) {
      return {
        logoSrc: wambaSvg,
        title: 'Wamba',
        alias: 'wamba',
        containerStyles: css`
          background: var(--foreground-default);
        `,
      }
    }
    if (yonja) {
      return {
        logoSrc: yonjaSvg,
        title: 'Yonja',
        alias: 'Yonja',
        containerStyles: css`
          background: ${Colors.yonjaBackground};
        `,
      }
    }
    return {
      logoSrc: mambaLight ? mambaLightSvg : mambaSvg,
      title: 'Mamba',
      alias: 'mamba',
      containerStyles: css`
        background: var(--foreground-default);
      `,
    }
  }, [loveMailRu, mambaLight, wamba, yonja])
}
