export const createLink = () => {
  try {
    const oneLinkUrl = window.AF_SMART_SCRIPT.generateOneLinkURL({
      oneLinkURL: 'https://mamba.onelink.me/2068119115',
      afParameters: {
        mediaSource: { keys: ['utm_source'], defaultValue: 'any_source' },
        campaign: {
          keys: ['utm_campaign'],
          defaultValue: 'any_campaign_name',
        },
        afCustom: [{ paramKey: 'af_ss_ui', defaultValue: 'true' }],
      },
    })

    return oneLinkUrl.clickURL
  } catch (error) {
    console.error('Error generating smart script one link banner url', error)
  }
}
