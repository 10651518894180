import { isLoveMailru, isMambaLite, isWamba, isYonja } from 'common/constants'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

import { useContent } from './useContent'

export const useCreateBannerInfo = () => {
  const { partnerId } = useShallowEqualSelector(
    ({ systemReducer: { partnerId } }) => ({
      partnerId,
    })
  )

  const wamba = isWamba(partnerId)
  const loveMailRu = isLoveMailru(partnerId)

  const { alias, logoSrc, title, containerStyles } = useContent({
    loveMailRu,
    wamba,
    mambaLight: isMambaLite(partnerId),
    yonja: isYonja(partnerId),
  })

  return {
    alias,
    logoSrc,
    title,
    containerStyles,
  }
}
